function initializeCountdowns() {

    function initializeCountdown(/** @type {HTMLTimeElement} */ countdown) {
        const targetTime = new Date(countdown.getAttribute('data-datetime'));

        function updateTime() {
            const diffInSeconds = Math.floor(Math.max(targetTime.getTime() - Date.now(), 0) / 1000);

            const days = Math.floor(diffInSeconds / (24 * 60 * 60));
            const hours = Math.floor((diffInSeconds % (24 * 60 * 60)) / (60 * 60));
            const minutes = Math.floor((diffInSeconds % (60 * 60)) / 60);
            const seconds = Math.floor(diffInSeconds % 60);

            let daysString = `${days}`;
            while (days.length < 2) {
                daysString = `0${daysString}`;
            }

            let hoursString = `${hours}`;
            while (hoursString.length < 2) {
                hoursString = `0${hoursString}`;
            }

            let minutesString = `${minutes}`;
            while (minutesString.length < 2) {
                minutesString = `0${minutesString}`;
            }

            let secondsString = `${seconds}`;
            while (secondsString.length < 2) {
                secondsString = `0${secondsString}`;
            }

            countdown.innerHTML = `
                <span class="imitateMonospace" data-monospaced-content="0">
                    <span class="imitateMonospace__container">${daysString}</span>
                </span> day${days !== 1 ? 's' : ''}
                <span class="imitateMonospace" data-monospaced-content="00">
                    <span class="imitateMonospace__container">${hoursString}</span>
                </span> hr${hours !== 1 ? 's' : ''}
                <span class="imitateMonospace" data-monospaced-content="00">
                    <span class="imitateMonospace__container">${minutesString}</span>
                </span> min${minutes !== 1 ? 's' : ''}
                <span class="imitateMonospace" data-monospaced-content="00">
                    <span class="imitateMonospace__container">${secondsString}</span>
                </span> sec${seconds !== 1 ? 's' : ''}`;

            if (diffInSeconds > 0) {
                setTimeout(updateTime, 1000);
            }
        }

        updateTime();
    }

    const timeElements = document.querySelectorAll('.js-newsletterCountdown');

    timeElements.forEach(initializeCountdown);
}

document.readyState === 'loading'
    ? document.addEventListener('DOMContentLoaded', initializeCountdowns)
    : initializeCountdowns();
