import {CookieManager} from './cookie.js';

export function manageNonSubscriberContent() {
    const nonSubscriberContent = document.querySelectorAll('.js-nonSubscriberOnly');

    if (CookieManager.read('has_subscribed_to_newsletter') === 'yes') {
        nonSubscriberContent.forEach((/** @type {HTMLElement} */ element) => element.remove());
    } else {
        nonSubscriberContent.forEach((/** @type {HTMLElement} */element) => (element.hidden = false));
    }
}
