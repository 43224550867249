/**
 * Adds an ability to toggle password input between hidden and shown states
 */

/**
 *
 * @param {HTMLElement} button
 */
function toggleInputVisibility(button) {
    const input = button.parentElement.querySelector('input');
    if (input.type === 'password') {
        input.type = 'text';
        button.textContent = 'Hide';
    } else {
        input.type = 'password';
        button.textContent = 'Show';
    }
}

/**
 * Register button click listeners and saves all found buttons
 */
function registerClickListeners() {
    const passwordInputs = [...document.querySelectorAll('.js_form_passwordInputButton')];
    passwordInputs.forEach((node) => {
        node.addEventListener('click', () => toggleInputVisibility(node));
    });
}

/**
 * Init component on the DOM load
 */
export function initializeComponent() {
    registerClickListeners();
}

document.readyState === 'loading'
    ? document.addEventListener('DOMContentLoaded', initializeComponent)
    : initializeComponent();
