/**
 * @module lazyLoader
 * @description A singleton class to handle LazyLoad instance that loads images/iframes while they reach a threshold
 * value relatively to viewport bounds. Positive threshold values load sooner, negative values load later.
 * For more info see {@see https://github.com/verlok/lazyload}
 *
 * There is an extra feature added to start animations for custom elements that reach the viewport — `data-lazyload`.
 * For more info {@see `components/lazyLoader/lazyLoader.css`).
 */
import LazyLoad from 'vanilla-lazyload/dist/esm/lazyload.js';
const ELEMENTS_SELECTOR = 'img[data-src],[data-lazyload]';
const PLYR_SPRITE_URL = '/svg/plyr-sprite.svg';

const LazyLoadInstance = new LazyLoad({
    elements_selector: ELEMENTS_SELECTOR,
    thresholds: '60%', // % of the viewport height
    unobserve_entered: true,
    callback_enter(element) {
        // Add a class name to element when it starts loading. Such allows, for example, to show a placeholder image.
        element.classList.add('lazy-loaded');

        /*
         * Check for `data-lazyload` attribute on element first. If exists then remove it after a delay specified in it.
         * Using this attribute may be useful when the element is not an image/video/iframe, but any custom element
         * that has to be shown after a delay after reaching the viewport. For example, embedded SVG with animations.
         */
        const lazyloadDelay = parseInt(element.getAttribute('data-lazyload') || 0);
        if (lazyloadDelay) {
            setTimeout(() => element.removeAttribute('data-lazyload'), lazyloadDelay);
        }
    },
});

LazyLoadInstance.updateWithinContainer = (element) => {
    LazyLoadInstance.update(element.querySelectorAll(ELEMENTS_SELECTOR));
};

// Use an IntersectionObserver to load <video> elements as the verlok/lazyload
// library will trigger video src to be automatically downloaded on Safari.
const videoIo = new IntersectionObserver((entries, observer) => {
    entries.forEach(function (entry) {
        const {target} = entry;
        if (entry.intersectionRatio > 0) {
            lazyInitVideo(target);
            observer.unobserve(target);
         }
     });
});

/**
 * Adds special query params to YouTube’s video URL.
 * See https://developers.google.com/youtube/player_parameters
 *
 * @param {string} url
 * @returns {string}
 */
function appendYoutubeQueryParameters(url) {
    const parametersSeparator = url.includes('?') ? '&' : '?';
    const origin = `${location.protocol}//${location.hostname}`;

    return `${url}${parametersSeparator}origin=${origin}&iv_load_policy=3&modestbranding=1&playsinline=1&enablejsapi=1&showinfo=0&rel=0`;
}

/**
 * Adds special query params to Vimeos’s video URL.
 * See https://developers.google.com/youtube/player_parameters
 *
 * @param {string} url
 * @returns {string}
 */
function appendVimeoQueryParameters(url) {
    const parametersSeparator = url.includes('?') ? '&' : '?';
    const origin = `${location.protocol}//${location.hostname}`;

    return `${url}${parametersSeparator}origin=${origin}&loop=false&byline=false&portrait=false&title=false&speed=true&transparent=0&gesture=media`;
}

/**
 * Init video player for DOM element
 * @param {HTMLVideoElement} element
 * @param {object} options additional Plyr options
 */
function lazyInitVideo(element, options = {}) {
    if (element.classList.contains('video-gif')) {
        if (element.dataset.src) {
            element.src = element.dataset.src;
        }
        return;
    }
    import('./video.js').then(({initVideo}) => {
        initVideo(element, {...options, iconUrl: PLYR_SPRITE_URL});
    });
}

/**
 * Handle mouse/touch event on iframe with video
 * @param {Event} event Mouse event object
 */
function handleIframeMouseOver(event) {
    const element = event.target;
    // initialize video player only once
    element.removeEventListener('mouseover', handleIframeMouseOver);
    // start downloading external resources
    element.src = element.dataset.src;
    // Fix aspect ratio for Vimeo videos using extra parameter
    lazyInitVideo(element.parentNode, {ratio: '16:9'});
}

/**
 * Setup various video players in container
 * @param {HTMLElement|Document} container
 */
function initVideoWithinContainer(container) {
    container.querySelectorAll('video').forEach((video) => {
        // hide browser video player controls immediately
        video.controls = false;
        videoIo.observe(video);
    });

    container.querySelectorAll('iframe[data-src]').forEach((iframe) => {
        const element = iframe;
        const url = element.dataset.src;
        const isYoutube = url && url.includes('youtube');
        const isVimeo = url && url.includes('vimeo');
        const isVideoEmbedded = isYoutube || isVimeo;
        // display poster using srcdoc attribute
        if (isYoutube) {
            element.style.backgroundImage = `url(https://img.youtube.com/vi/${url.split('/').pop()}/hqdefault.jpg)`;
            element.style.backgroundSize = '100%';
            element.dataset.src = appendYoutubeQueryParameters(url);
        } else if (isVimeo) {
            // vimeo doesn't provide an easy access to poster URL :(
            // https://stackoverflow.com/questions/1361149/get-img-thumbnails-from-vimeo
            element.dataset.src = appendVimeoQueryParameters(url);
        }
        if (isVideoEmbedded) {
            const videoPosterCssPath = document.getElementById('videoPosterCssPath').href;
            element.srcdoc = `
            <link rel=stylesheet href="${videoPosterCssPath}">
            <button>
                <svg aria-hidden=true>
                    <g viewBox="0 0 18 18"><path d="M15.562 8.1L3.87.225c-.818-.562-1.87 0-1.87.9v15.75c0 .9 1.052 1.462 1.87.9L15.563 9.9c.584-.45.584-1.35 0-1.8z"/></g>
                </svg>
            </button>
            `;
            element.addEventListener('mouseover', handleIframeMouseOver);
        }
    });
}

initVideoWithinContainer(document);

document.addEventListener('lazy:initContent', (event) => {
    LazyLoadInstance.updateWithinContainer(event.target);
}, true);

document.addEventListener('lazy:initVideo', (event) => {
    initVideoWithinContainer(event.target);
}, true);
