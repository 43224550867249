const DOCUMENT = document;
const CSS_ERROR_CLASS = 'error';
const VALIDATION_CHECK_TIMEOUT = 700;

/**
 * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} field
 */
function validateFormField(field) {
    if (! field.checkValidity()) {
        return;
    }

    let parent = field.parentNode;
    if (parent instanceof HTMLLabelElement) {
        parent = parent.parentNode;
    }

    if (parent instanceof HTMLElement) {// in rare cases, parent.parentNode is null (detached from DOM)
        parent.classList.remove(CSS_ERROR_CLASS);
    }

    field.setAttribute('aria-invalid', 'false');
    field.labels?.forEach((label) => {
        label.classList.remove(CSS_ERROR_CLASS);
    });

    const form = field.form;
    if (! (form instanceof HTMLFormElement)) return;

    if ('dynamicSubmit' in form.dataset) {
        form.querySelectorAll('[type="submit"]').forEach((submitButton) => {
            if (submitButton.form === form) {
                // use private field to ignore global listener for 'invalid'
                // event because Form#checkValidity() triggers 'invalid'
                // for every failing form child element
                form._ignoreInvalidListener = true;

                if (form.checkValidity()) {
                    submitButton.disabled = false;
                }

                delete form._ignoreInvalidListener;
            }
        });
    }
}

DOCUMENT.addEventListener(
    'invalid',
    (event) => {
        /** @type {HTMLInputElement|HTMLSelectElement} */
        const field = event.target;
        // ignore elements with disabled validation
        if (field.hasAttribute('formnovalidate')) return;

        const form = field.form;
        // skip elements without a parent form
        // ignore forms with disabled validation
        if (!form || form.hasAttribute('novalidate') || form._ignoreInvalidListener) return;
        // disable native browser validation popovers
        if (form.method !== 'DIALOG') {
            event.preventDefault();
        }

        let parent = field.parentNode;
        if (parent instanceof HTMLLabelElement) {
            parent = parent.parentNode;
        }

        if (parent instanceof HTMLElement) {// in rare cases, parent.parentNode is null (detached from DOM)
            parent.classList.add(CSS_ERROR_CLASS);
        }

        field.setAttribute('aria-invalid', 'true');
        field.labels?.forEach((label) => {
            label.classList.add(CSS_ERROR_CLASS);
        });

        // Automatically remove spinner animation for spinners
        // inside a form. This eliminates the need to add a js
        form.querySelectorAll('.ixdf-spinner').forEach((spinner) => {
            spinner.classList.remove('button--loading');
        });

        if ('dynamicSubmit' in form.dataset) {
            form.querySelectorAll('[type="submit"]').forEach((submitButton) => {
                if (submitButton.form === form) {
                    submitButton.disabled = true;
                }
            });
        }
        // focus on the first invalid form element
        if (form.querySelector(':invalid') === field && form.method !== 'DIALOG') {
            field.focus();
        }
    },
    true
);

DOCUMENT.addEventListener(
    'input',
    (event) => {
        /** @type {HTMLFormElement} */
        const formElement = event.target;
        /**
         * TODO: 'rt-editor' web component is not form-associated, causing TypeError due to missing 'hasAttribute'.
         * See /resources/js/components/ratingInput.js for a similar fix with a polyfill.
         * The issue exist in old Safari versions
         */
        // ignore elements with disabled validation
        if (formElement.hasAttribute('formnovalidate')) return;

        const form = formElement.form;
        // skip elements without a parent form
        // ignore forms with disabled validation
        if (!form || form.hasAttribute('novalidate')) return;

        const lastTimeoutId = formElement._validationTimeoutId;
        if (lastTimeoutId) clearTimeout(lastTimeoutId);
        formElement._validationTimeoutId = setTimeout(() => {
            clearTimeout(formElement._validationTimeoutId);
            delete formElement._validationTimeoutId;
            validateFormField(formElement);
        }, VALIDATION_CHECK_TIMEOUT);
    },
    true
);

DOCUMENT.addEventListener(
    'change',
    (event) => {
        const formElement = event.target;
        // ignore elements with disabled validation
        if (formElement.hasAttribute('formnovalidate')) return;

        const form = formElement.form;
        // skip elements without a parent form
        // ignore forms with disabled validation
        if (!form || form.hasAttribute('novalidate')) return;

        validateFormField(formElement);
    },
    true
);

function scrollToErrorInputOnPageLoad() {
    const inputWithError = document.querySelector('.form__field.error');
    inputWithError?.scrollIntoView({block: 'center'});
}

document.readyState === 'loading'
    ? document.addEventListener('DOMContentLoaded', scrollToErrorInputOnPageLoad)
    : scrollToErrorInputOnPageLoad();
