import {getHttpClient} from '../../modules/httpClient.js';

/**
 * @typedef {import('./adDef').Ad} Ad
 * @typedef {import('modules/httpClientDef').HttpClient} HttpClient
 */

class AdArea extends HTMLElement {
    /** @type {HttpClient} */
    #httpClient;

    /** @type {Array<Ad>} */
    #ads = [];

    /** @type {string} */
    #mode = 'auto';

    /** @type {?string} */
    #location;

    /** Called each time the element is added to the document. */
    connectedCallback() {
        this.#httpClient = getHttpClient();

        this.#mode = this.getAttribute('mode') === 'managed' ? 'managed' : 'auto';
        this.#location = this.getAttribute('location') || 'banner';

        if (this.#mode === 'auto') {
            this.#autoRender();
        }
    }

    /**
     * @returns {Promise<Ad[]>}
     */
    async #fetchAds() {
        const adResponse = await this.#httpClient.get(`/onsite-banners?member_country_ads`); // @route 'onsiteAds.index';
        return adResponse?.data || [];
    }

    /**
     * @returns {Promise<Ad|null>}
     */
    async #getAd() {
        if (this.#ads.length === 0) {
            this.#ads = await this.#fetchAds();
        }

        return this.#ads.length ? this.#getRandomAd() : null;
    }

    async #getRandomAd() {
        const adIndex = Math.floor(Math.random() * this.#ads.length);
        return this.#ads[adIndex];
    }

    /**
     * @param {Ad} ad
     * @returns {string}
     */
    #getAdImageUrl(ad) {
        const {
            banner_image_url: bannerImageUrl,
            portrait_image_url: sidebarImageUrl,
            column_image_url: columnImageUrl,
        } = ad;

        switch (this.#location) {
            case 'sidebar':
                return sidebarImageUrl;
            case 'column':
                return columnImageUrl;
        }
        return bannerImageUrl;
    }

    /**
     * @param {Ad} ad
     * @returns {HTMLPictureElement}
     */
    #generateAdImageElements(ad) {
        const {
            description,
            banner_image_url: bannerImageUrl,
            portrait_image_url: sidebarImageUrl,
            column_image_url: columnImageUrl,
        } = ad;

        const picture = document.createElement('picture');

        const img = document.createElement('img');
        img.src = this.#getAdImageUrl(ad);
        img.alt = description;
        img.loading = 'lazy';

        // Animate opacity on load
        img.style.opacity = '0';
        img.addEventListener('load', () => {
            img.style.opacity = '';
        });

        // Add banner ad image source
        if (this.#location === 'banner') {
            const source = document.createElement('source');
            source.srcset = bannerImageUrl;
            source.media = '(min-width: 650px)';
            picture.append(source);
        }

        // Add column ad image source
        if (this.#location === 'banner' || this.#location === 'column') {
            const source = document.createElement('source');
            source.srcset = columnImageUrl;
            source.media = '(min-width: 350px)';
            picture.append(source);
        }

        // Add sidebar ad image source
        if (this.#location === 'banner' || this.#location === 'column' || this.#location === 'sidebar') {
            const source = document.createElement('source');
            source.srcset = sidebarImageUrl;
            picture.append(source);
        }

        picture.append(img);

        return picture;
    }

    /**
     * @param {Ad} ad
     */
    async #renderAd(ad) {
        const {tracking_url: trackingUrl} = ad;

        const link = document.createElement('a');
        link.href = trackingUrl;
        link.append(this.#generateAdImageElements(ad));

        this.innerHTML = '';
        this.append(link);
    }

    async #autoRender() {
        const ad = await this.#getAd();

        if (!ad) {
            this.remove();
            return;
        }

        this.#renderAd(ad);
    }
}

customElements.define('ixdf-ad-area', AdArea);
