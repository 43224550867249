/**
 * @module analyticsListener
 * @description Listen to Events and modify GA’s dataLayer based on them
 */
export class AnalyticsListener {
    /** Register all event listeners */
    registerListeners() {
        document.addEventListener('checkout', (checkoutEvent) => {
            this.getDataLayer().push({
                event: 'checkout',
                ecommerce: checkoutEvent.detail.ecommerce,
            });
        });

        // raw idea: find unused functionality by measuring clicks. Used by developers only
        document.addEventListener('dev:metric', (checkoutEvent) => {
            this.getDataLayer().push({
                event: 'metric',
                category: checkoutEvent.detail.category,
                action: checkoutEvent.detail.action,
                type: checkoutEvent.detail.type,
            });
        });
    }

    /**
     * @private
     * @returns {{push: Function}}
     */
    getDataLayer() {
        const isGaAvailable = window.ga && window.ga.loaded;
        return isGaAvailable ? window.dataLayer : this.getNullAnalytics();
    }

    /**
     * @private
     * @returns {{push: Function}}
     */
    getNullAnalytics() {
        return {
            push: Array.prototype.push,
        };
    }
}
