/** @typedef {import('../../../../node_modules/lottie-web/index.d.ts').AnimationItem} AnimationItem */
/** @typedef {import('../../../../node_modules/lottie-web/index.d.ts').LottiePlayer} LottiePlayer */

class LottieAnimation extends HTMLElement {
    /** @type {?AnimationItem} */
    #animation;

    #autoplay = true;
    #loop = true;

    /** Called each time the element is added to the document. */
    connectedCallback() {
        this.#initializeAttributes();
    }

    /**
     * An array containing the names of all attributes for which the element needs change notifications.
     * @returns {Array<string>}
     */
    static get observedAttributes() {
        return ['src', 'style'];
    }

    /**
     * Setter for 'src' property
     * @param {string} source
     */
    set src(source) {
        this.setAttribute('src', source);
    }

    /**
     * @param {string} name Name of attribute that has changed
     * @param {string} oldValue Value before change
     * @param {string} newValue Value after change
     * @returns {void}
     */
    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue === newValue) {
            return;
        }

        switch (name) {
            case 'src':
                this.#renderAnimation(newValue);
                return;
            case 'style':
                this.style.cssText = newValue;
                return;
        }
    }

    /**
     * @returns {void}
     */
    #initializeAttributes() {
        const autoplayAttribute = this.getAttribute('autoplay');
        this.#autoplay = autoplayAttribute !== null && autoplayAttribute !== 'false';

        const loopAttribute = this.getAttribute('loop');
        this.#loop = loopAttribute !== null && loopAttribute !== 'false';
    }

    /**
     * @returns {void}
     */
    #initializeShadowRoot() {
        if (!this.shadowRoot) {
            this.attachShadow({mode: 'open'});
        }

        this.shadowRoot.innerHTML = `
            <style>
                svg {
                    display: block;
                }
            </style>
      `;
    }

    #clearAnimation() {
        if (this.#animation) {
            this.#animation.destroy();
            this.#animation = null;
        }
    }

    /**
     * Render animation as defined in source JSON file
     *
     * @param {?string} source
     * @returns {Promise<void>}
     */

    async #renderAnimation(source) {
        if (!source) {
            this.#clearAnimation();
            return;
        }

        // Load 'lottie-web' dependency only when ready to render

        /** @type {LottiePlayer} */
        const Lottie = await import('lottie-web');

        this.#clearAnimation();
        this.#initializeShadowRoot();

        this.#animation = Lottie.loadAnimation({
            container: this.shadowRoot,
            renderer: 'svg',
            loop: this.#loop,
            autoplay: this.#autoplay,
            path: source,
            rendererSettings: {
                progressiveLoad: false,
                preserveAspectRatio: 'xMidYMid meet',
            },
        });
    }

    play() {
        this.#animation?.play();
    }

    stop() {
        this.#animation?.stop();
    }

    pause() {
        this.#animation?.pause();
    }
}

customElements.define('ixdf-animation', LottieAnimation);
